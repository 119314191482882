import { Container } from "./styles";
import githubIcon from "../../assets/github.svg"
// import DownloadApp from '../../assets/download.png'
import externalLink from "../../assets/external-link.svg"
import ScrollAnimation from "react-animate-on-scroll";


export function Project() {
  return (
    <Container id="project">
      <h2>My Projects</h2>
      <div className="projects">

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"> <title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg>
              <div className="project-links">
                <a href="https://github.com/CryptoFamilyNFT/papery" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" /></a>
                <a href="https://papery-gules.vercel.app" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a> </div>
            </header>
            <div className="body">
              <h3>Integrated Swap DApp with Chart and Meme Generator Editor</h3>
              <p> I developed a decentralized application (DApp) that features an integrated swap functionality, a chart for visualizing data, and a meme generator editor. The project was built using Material UI for the user interface, TypeScript for type safety, and Solana Web3.js for interacting with the Solana blockchain.
                The DApp allows users to seamlessly swap tokens on the Solana network, providing a user-friendly interface powered by Material UI components. The chart feature enables users to analyze market data and trends, while the meme generator editor allows them to create and customize memes with ease.</p>
            </div>
            <footer> <ul className="tech-list"> <li>Typescript</li> <li>Material UI</li> <li>SolanaWeb3.js</li> </ul> </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><title>Folder</title> <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path> </svg>
              <div className="project-links">
                <a href="https://github.com/CryptoFamilyNFT/DiviTrendBot" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" /> </a>
                <a href="https://divi-trend-bot.vercel.app" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" /></a>
              </div>
            </header>
            <div className="body">
              <h3>NFTBot: On-chain live data streaming</h3>
              <p>
                Developed an interactive NFTBot application using Javascript, Discord.js and ethers.js, enabling users to get real time data on on-chain NFT collection from opensea.
              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Javascript</li>
                <li>Discord.js</li>
                <li>ethers.js</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Folder</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://github.com/CryptoFamilyNFT/FLYBOB" target="\_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
                <a href="https://github.com/CodeVinayak/Jupyter-Notebook/blob/main/Credit%20Risk%20Analysis/Credit_Risk_Analysis.ipynb" target="\_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Credit Risk Analysis Project</h3>
              <p>
                I developed FlyBob, a Progressive Web Application (PWA) that integrates a database and on-chain EVM data. Utilizing Material UI for a responsive design, I implemented TypeScript for type safety and clarity. The application leverages ethers.js for blockchain interactions and Express.js for server-side functionality, ensuring seamless data management and user experience in the decentralized finance space.              </p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Material UI</li>
                <li>Typescript</li>
                <li>MongoDB</li>
                <li>Express.js</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b " stroke-width="1" stroke-linecap="round" stroke-linejoin="round" ><title>Folder</title><path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path></svg>
              <div className="project-links">
                <a href="https://github.com/orgs/DIVITREND/repositories" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
                <a href="https://app.divitrend.finance/" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>DiviTrend: Backend and Frontend for a DApp on ARB for Cryptocurrency Exchange and Staking | Organization </h3>
              <p>I developed (with my team mate Ethercode_Exit) backend and frontend for a decentralized application on the Arbitrum network that facilitates cryptocurrency exchange and staking. The project utilized TypeScript for robust type safety, Material UI for an intuitive user interface, Solidity for smart contract development, and ethers.js for seamless blockchain interactions. This comprehensive approach ensures a secure and efficient platform for users to manage their cryptocurrency assets.</p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>Material UI</li>
                <li>Solidity</li>
                <li>ethers.js</li>
                <li>Typescript</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="flipInX">
          <div className="project">
            <header>
              <svg width="50" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="#23ce6b" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
                <title>Code</title>
                <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z"></path>
              </svg>
              <div className="project-links">
                <a href="https://github.com/Crooks-Finance" target="_blank" rel="noreferrer">
                  <img src={githubIcon} alt="Visit site" />
                </a>
                <a href="https://github.com/Crooks-Finance" target="_blank" rel="noreferrer">
                  <img src={externalLink} alt="Visit site" />
                </a>
              </div>
            </header>
            <div className="body">
              <h3>Crooks Finance: Developed Backend, Frontend and Bot for a Decentralized Application on Cronos</h3>
              <p>I collaborated on building a decentralized application on the Cronos blockchain that enables the exchange and staking of cryptocurrencies. The project involved developing the backend, frontend, and a bot using TypeScript, Material UI, Solidity, ethers.js and Python. This comprehensive solution allows users to seamlessly trade and stake their digital assets, while the bot provides automated trading functionality to enhance user experience and optimize investment strategies in the DeFi ecosystem.</p>
            </div>
            <footer>
              <ul className="tech-list">
                <li>telegram.js</li>
                <li>discord.js</li>
                <li>Python</li>
                <li>Typescript</li>
                <li>Material UI</li>
                <li>Solidity</li>
                <li>ethers.js</li>
              </ul>
            </footer>
          </div>
        </ScrollAnimation>
      </div>
    </Container>
  );
}